import preset from '@rebass/preset';
import { merge } from 'lodash';

const theme = merge({}, preset, {
  breakpoints: ['768px', '992px', '1200px'],
  fonts: {
    body: 'Helvetica, sans-serif',
    heading: 'Helvetica, sans-serif',
  },
});

export default theme;
